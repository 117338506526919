import React, { Fragment } from "react";
import humanImage from "../../assets/images/Psychedelics-Human.svg";

const TitleBar = ({ title }) => {
  return (
    <Fragment>
      <h1 className="title trajan mb-0 pt-4" style={{ fontSize: "2.5em" }}>
        {title}
        <img src={humanImage} alt="Icon of a stick person" className="title-image" />
      </h1>
      <hr className="m-0 mt-1" />
    </Fragment>
  );
};

export default TitleBar;
