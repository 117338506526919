import React from "react";
import { Link } from "gatsby";
import { parseISO, format } from "date-fns";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogItem = ({ blog }) => {
  // console.log("BLOG", blog);

  const authorImg = getImage(blog.author.avatar.asset);
  const blogImg = getImage(blog.image.asset);

  return (
    <Card className="h-100">
      <CardBody className="p-3 h-100 d-flex flex-column">
        <CardHeader className="mb-3 d-flex flex-row">
          <GatsbyImage
            className="rounded-circle img-fluid img-raised mr-3"
            image={authorImg}
            alt="Author"
            style={{ height: "50px", width: "50px" }}
          />
          <div>
            <CardTitle className="font-weight-bold mb-0">
              {blog.author.name}
            </CardTitle>
            <CardText
              className="font-weight-light"
              style={{ fontSize: "14px" }}
            >
              {format(parseISO(blog.date), "PP")}
            </CardText>
          </div>
        </CardHeader>
        <GatsbyImage
          image={blogImg}
          alt={blog.title}
          style={{ maxHeight: "150px" }}
        />
        <CardTitle tag="h4" className="mt-2 mb-2">
          {blog.title}
        </CardTitle>
        <h6>{blog.subtitle}</h6>
        <Link
          tag="button"
          key={blog.slug.current}
          to={`/blog/${blog.slug.current}`}
          className="text-decoration-none mt-auto"
        >
          <Button className="m-0" block>
            Read Article
          </Button>
        </Link>
      </CardBody>
    </Card>
  );
};

export default BlogItem;
