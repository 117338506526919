import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "gatsby";

const BlogPagination = ({ currentPage = 1, totalPages }) => {
  const disablePrevious = () => currentPage === 1;
  const disableNext = () => currentPage === totalPages;

  return (
    <Pagination aria-label="Blog Pagination" className="mt-3">
      <Link
        to={`/blog/${currentPage - 1}`}
        className={`text-decoration-none ${
          disablePrevious() ? "disable-pointer-events" : ""
        }`}
      >
        <PaginationItem>
          <PaginationLink tag="span" previous />
        </PaginationItem>
      </Link>
      {[...Array(totalPages)].map((_, i) => {
        return (
          <Link to={`/blog/${i + 1}`} key={i} className="text-decoration-none">
            <PaginationItem active={i + 1 === currentPage}>
              <PaginationLink>{i + 1}</PaginationLink>
            </PaginationItem>
          </Link>
        );
      })}
      <Link
        to={`/blog/${currentPage + 1}`}
        className={`text-decoration-none ${
          disableNext() ? "disable-pointer-events" : ""
        }`}
      >
        <PaginationItem>
          <PaginationLink next />
        </PaginationItem>
      </Link>
    </Pagination>
  );
};

export default BlogPagination;
