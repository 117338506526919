import React, { useEffect, Fragment } from "react";
import { graphql } from "gatsby";
import { Container } from "reactstrap";
import BlogPagination from "../components/BlogPagination";
import BlogList from "../components/BlogList";
import SEO from "../components/SEO";
import TitleBar from "../components/Layout/TitleBar";
import BreadCrumbs from "../components/Layout/BreadCrumbs";

const BlogPage = ({ data, pageContext }) => { 
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const blogs = data.blogs.nodes;
  const { totalCount } = data.blogs;
  const { currentPage } = pageContext;
  const totalPages = Math.ceil(totalCount / 6);

  return (
    <Fragment>
      <SEO 
        title="Blog"
        description="Cannabis and Psychedelics Law Group LLP appearing in the media, discussing law and litigation surrounding cannabis and psychedelics in Ontario and Canada."
      />

      <div className="wrapper mt-5">
        <div className="main bg-grey">
          <Container>
            <TitleBar title="Blog" />
            <BreadCrumbs page="Blog" />
            <BlogPagination currentPage={currentPage} totalPages={totalPages} />
            <BlogList blogs={blogs} />
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export const query = graphql`
  query BlogsQuery($skip: Int! = 0) {
    blogs: allSanityBlog(
      limit: 6
      skip: $skip
      sort: { fields: [date], order: DESC }
    ) {
      totalCount
      nodes {
        title
        subtitle
        metaDescription
        _rawBody
        slug {
          current
        }
        author {
          name
          avatar {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
            }
          }
        }
        date
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default BlogPage;
