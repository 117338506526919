import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "gatsby";

const BreadCrumbs = ({ page }) => {
  return (
    <Breadcrumb className="mt-3 breadcrumb-text">
      <BreadcrumbItem>
        <Link to="/" className="text-secondary">
          Home
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem active>{page}</BreadcrumbItem>
    </Breadcrumb>
  );
};

export default BreadCrumbs;
