import React from "react";
import { Row, Col } from "reactstrap";
import BlogItem from "./BlogItem";

const BlogList = ({ blogs }) => {
  return (
    <Row className="mt-4">
      {blogs.map((blog, i) => {
        return (
          <Col md="6" lg="4" key={i} style={{marginBottom: "30px"}}>
            <BlogItem blog={blog} />
          </Col>
        );
      })}
    </Row>
  );
};

export default BlogList;
